import { classNames, randomId } from '@pwskills/rachnaui/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { IFaqsDetails } from '@/interfaces/homePage';
import CourseDescriptionContext from '../../../context/CourseDescriptionContext';
import CategoryPageContext from '../../../context/CategoryPageContext';
import { dataLayer } from '../../../utils/common/dataLayer.utils';

type Props = {
  id: string;
  faqsList?: IFaqsDetails[];
};

const FaqsAnswer = ({ id, faqsList }: Props) => {
  const [height, setHeight] = useState<any>([]);
  const divRef = useRef(null);
  const [currentHeight, setCurrentHeight] = useState<number>(0);
  const [totalHeight, setTotalHeight] = useState<number>(0);
  const { courseDetails } = useContext(CourseDescriptionContext);
  const { categoryDetails } = useContext(CategoryPageContext);

  const sendDataLayerEvent = (section: string, videoURL: any) => {
    const dataLayerObj = {
      event: 'video_play_click',
      section_name: section,
      course_id: courseDetails?._id || categoryDetails?._id || '',
      video_url: videoURL,
    };
    dataLayer(dataLayerObj);
  };

  const onPlay = (videoURL: string | undefined) => {
    sendDataLayerEvent('faq', videoURL);
  };

  useEffect(() => {
    const sectionHeight: { height: number; id: string | null }[] = [];

    if (divRef.current) {
      const currentElement = divRef.current as HTMLElement;
      const arr = Array.from(currentElement.children) as HTMLElement[];

      arr.forEach((div, idx) => {
        if (idx === 0) setCurrentHeight(div?.clientHeight);
        const existingObjectIndex = sectionHeight.findIndex(
          (obj: any) => obj.id === div.getAttribute('data-id')
        );

        if (existingObjectIndex !== -1) {
          sectionHeight[existingObjectIndex].height = div?.clientHeight;
        } else {
          sectionHeight.push({ id: div.getAttribute('data-id'), height: div?.clientHeight });
        }
      });
      setHeight(sectionHeight);
    }
  }, [divRef, id]);

  useEffect(() => {
    let temp = 0;
    for (let index = 0; index < height.length; index += 1) {
      if (height[index].id === id) {
        setCurrentHeight(height[index].height);
        break;
      }
      temp += height[index].height;
    }
    setTotalHeight(temp);
  }, [height, id]);

  return (
    <div
      style={{
        height: `${currentHeight}px`,
        transform: `translate3d(0, -${totalHeight}px, 0)`,
      }}
      className="w-full transition-all duration-300 ease-linear"
      ref={divRef}
    >
      {faqsList &&
        faqsList?.map((faq) => {
          return (
            <div
              data-id={faq?._id}
              className={classNames(
                'flex-col items-start justify-center w-full',
                faq._id === id ? 'flex' : 'flex',
                faq?.videoLink ? 'gap-y-2 pb-8' : ''
              )}
              key={randomId()}
            >
              <div className="w-full">
                {faq?.videoLink && faq?.videoLink.trim() !== '' && (
                  <ReactPlayer
                    url={faq.videoLink}
                    height="255px"
                    width="100%"
                    controls
                    onPlay={() => onPlay(faq.videoLink)}
                    progressInterval={2000}
                    onLoad
                  />
                )}
              </div>
              <div className="w-full text-justify text-gray-850">{faq?.answer}</div>
            </div>
          );
        })}
    </div>
  );
};

export default FaqsAnswer;
